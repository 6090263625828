exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-asdessin-tsx": () => import("./../../../src/pages/asdessin.tsx" /* webpackChunkName: "component---src-pages-asdessin-tsx" */),
  "component---src-pages-diagnosis-cat-tsx": () => import("./../../../src/pages/diagnosis/cat.tsx" /* webpackChunkName: "component---src-pages-diagnosis-cat-tsx" */),
  "component---src-pages-diagnosis-dog-tsx": () => import("./../../../src/pages/diagnosis/dog.tsx" /* webpackChunkName: "component---src-pages-diagnosis-dog-tsx" */),
  "component---src-pages-diagnosis-dolphin-tsx": () => import("./../../../src/pages/diagnosis/dolphin.tsx" /* webpackChunkName: "component---src-pages-diagnosis-dolphin-tsx" */),
  "component---src-pages-diagnosis-eagle-tsx": () => import("./../../../src/pages/diagnosis/eagle.tsx" /* webpackChunkName: "component---src-pages-diagnosis-eagle-tsx" */),
  "component---src-pages-diagnosis-index-tsx": () => import("./../../../src/pages/diagnosis/index.tsx" /* webpackChunkName: "component---src-pages-diagnosis-index-tsx" */),
  "component---src-pages-diagnosis-lion-tsx": () => import("./../../../src/pages/diagnosis/lion.tsx" /* webpackChunkName: "component---src-pages-diagnosis-lion-tsx" */),
  "component---src-pages-diagnosis-monkey-tsx": () => import("./../../../src/pages/diagnosis/monkey.tsx" /* webpackChunkName: "component---src-pages-diagnosis-monkey-tsx" */),
  "component---src-pages-diagnosis-owl-tsx": () => import("./../../../src/pages/diagnosis/owl.tsx" /* webpackChunkName: "component---src-pages-diagnosis-owl-tsx" */),
  "component---src-pages-diagnosis-peafowl-tsx": () => import("./../../../src/pages/diagnosis/peafowl.tsx" /* webpackChunkName: "component---src-pages-diagnosis-peafowl-tsx" */),
  "component---src-pages-diagnosis-question-tsx": () => import("./../../../src/pages/diagnosis/question.tsx" /* webpackChunkName: "component---src-pages-diagnosis-question-tsx" */),
  "component---src-pages-diagnosis-theory-tsx": () => import("./../../../src/pages/diagnosis/theory.tsx" /* webpackChunkName: "component---src-pages-diagnosis-theory-tsx" */),
  "component---src-pages-diagnosis-types-tsx": () => import("./../../../src/pages/diagnosis/types.tsx" /* webpackChunkName: "component---src-pages-diagnosis-types-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-introduction-tsx": () => import("./../../../src/pages/introduction.tsx" /* webpackChunkName: "component---src-pages-introduction-tsx" */),
  "component---src-templates-archives-category-tsx": () => import("./../../../src/templates/archives/category.tsx" /* webpackChunkName: "component---src-templates-archives-category-tsx" */),
  "component---src-templates-archives-post-tsx": () => import("./../../../src/templates/archives/post.tsx" /* webpackChunkName: "component---src-templates-archives-post-tsx" */)
}

